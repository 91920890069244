import { ProtectedApi } from "../Helpers/api";

export const GetTimesheet = async (id, payload) => {
  return ProtectedApi.get(`/timesheet/mission/${id}`, {
    params: { ...payload },
  });
};

export const GetTimesheets = async (id, payload) => {
  return ProtectedApi.get(`/timesheet/mission/${id}/many`, {
    params: { ...payload },
  });
};

export const SubmitTimesheet = async (payload) => {
  return ProtectedApi.post(`/timesheet/submit`, { ...payload });
};
export const UpdateTimesheet = async (payload) => {
  return ProtectedApi.post(`/timesheet/update`, { ...payload });
};
