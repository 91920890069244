import React, { useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import style from "./style.module.css";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import TimesheetPopup from "../../Components/TimesheetPopup";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import {
  GetTimesheet,
  SubmitTimesheet,
} from "../../Services/Timesheet";
import toast from "./../../Helpers/toast";
import Loader from "../../Components/Loader";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  getAllWeekendsEvents,
  getRangeWeekDaysBetweenDate,
} from "../../Helpers/date";
import { TimesheetDayPopup } from "../../Components/TimesheetPopup/TimesheetDayPopup";
import { TimesheetConfirmationPopup } from "../../Components/TimesheetConfirmationPopup";
import MobileNavigation from "../../Components/Profil/MobileNavigation";
import HelperButton from "../../Components/HelperButton";
import { toastError } from "../../utils";

const Months = [
  "Janvier",
  "Février",
  "Mars",
  "Avril",
  "Mai",
  "Juin",
  "Juillet",
  "Aout",
  "Septembre",
  "Octobre",
  "Novembre",
  "Décembre",
];

const today = new Date();
const currentMonthToDisplay =
  today.getDate() > 20
    ? today.getMonth() + 1
    : today.getMonth() === 0
    ? 12
    : today.getMonth();
const currentYearToDisplay =
  currentMonthToDisplay === 12 && Number(today.getMonth()) === 0
    ? today.getFullYear() - 1
    : today.getFullYear();

function formatDate(date) {
  return date.toLocaleDateString("fr-FR").split("/").reverse().join("-");
  //return date.toISOString().slice(0, 10).replace(/-/g, "-");
}

//copies the date as a UTC date, ignores de user's locale
function forceUTC(date) {
  return new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
  );
}

const Timesheet = ({ match, history, location }) => {
  const calendar = useRef(null);
  const currentConsultant = useSelector(
    (state) => state.userReducer.consultant
  );
  const [linkedSociety, setLinkedSociety] = useState({});
  const [selectAllOpenDays, setSelectAllOpenDays] = useState(true);
  const [calendarApi, setCalendarApi] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [status, setStatus] = useState(false);

  const [events, setEvents] = useState([]);
  const [comment, setComment] = useState("");
  const [id, setId] = useState("");
  const [duration, setDuration] = useState(0);
  const [missions, setMissions] = useState([]);
  const [currentEvent, setCurrentEvent] = useState({
    date: "",
    percentage: 0,
  });
  const [openPeriodPopup, setOpenPeriodPopup] = useState(false);
  const [openSelectDayPopup, setOpenSelectDayPopup] = useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  const [holidays, setHolidays] = useState([]);
  useEffect(() => {
    if (currentConsultant) {
      const { year, month } = match.params;
      setComment("");
      setMissions(currentConsultant.missions);
      if (calendar.current) {
        setCalendarApi(calendar.current.getApi());
      }
      setLoaded(true);
      if (year && month) {
        setLoading(true);
        GetTimesheet(match.params.id, {
          year: match.params.year,
          month: match.params.month,
          consultantId: currentConsultant.id,
        }).then((res) => {
          let holidaysEvents = [];
          let weekendsEvents = [];
          if (res.data.publicHolidays) {
            const holidays = res.data.publicHolidays;
            setHolidays(holidays);
            holidaysEvents = holidays.map((h) => ({
              id: uuidv4(),
              date: formatDate(new Date(h.date)),
              unparsedDate: new Date(h.date),
              percentage: 0,
              isHoliday: true,
              display: "background",
            }));
          }
          weekendsEvents = getAllWeekendsEvents(year, month - 1).map(
            (event) => ({
              id: uuidv4(),
              date: formatDate(new Date(event)),
              unparsedDate: new Date(event),
              percentage: 0,
              isWeekend: true,
              display: "background",
            })
          );
          setEvents([...holidaysEvents, ...weekendsEvents]);

          if (res.data.timesheet) {
            parseEvents(res.data.timesheet);
          } else {
            setId("");
            setStatus("PENDING");
            setDuration(0);
          }
          setLoading(false);
        });
      }
    }
  }, [currentConsultant, match.params]);

  const onDateClick = (e) => {
    setCurrentEvent(e);
    setOpenSelectDayPopup(true);
  };

  const parseEvents = (data) => {
    setId(data.id);
    setStatus(data.status);
    setComment(data.comment);
    const events = data.days?.map((day) => ({
      id: day.id,
      date: formatDate(new Date(day.date)),
      unparsedDate: new Date(day.date),
      percentage: Number(day.percentage),
      display: "background",
    }));
    setEvents(events ?? []);
    setDuration(data.duration ?? 0);
    setLinkedSociety(data.linkedSociety);
  };

  const submitTimesheet = (passedEvents, updatedComment) => {
    const { year, month } = match.params;
    const missionId = match.params.id;
    const days = passedEvents.map((ev) => ({
      id: ev.id,
      date: forceUTC(ev.unparsedDate),
      percentage: ev.percentage,
    }));
    const duration = days.reduce(
      (prevValue, currValue) => prevValue + currValue.percentage,
      0
    );
    const formValues = {
      year,
      month,
      days,
      consultant: { id: currentConsultant.id },
      linkedSociety:
        linkedSociety && linkedSociety.id
          ? linkedSociety
          : currentConsultant?.society,
      mission: { id: missionId },
      comment: updatedComment ?? comment,
      duration,
      status: "SUBMITTED",
    };

    const timesheet = {
      id,
      year: year,
      month: month,
      mission: { id: missionId },
      consultant: { id: currentConsultant.id },
      linkedSociety: currentConsultant?.society
        ? { id: currentConsultant.society?.id }
        : null,
      ...formValues,
    };
    updateTimeSheet(timesheet);
  };

  const isTimesheetEditable = (status) => {
    return (
      status !== "ADMIN_APPROVED" &&
      status !== "APPROVED" &&
      status !== "SUBMITTED" &&
      status !== "SENT_TO_SIGN"
    );
  };

  const handleSubmission = (events, comment) => {
    setEvents(events);
    setComment(comment);
    submitTimesheet(events, comment);
    setOpenPeriodPopup(false);
  };

  const updateTimeSheet = (formValues) => {
    SubmitTimesheet(formValues)
      .then((res) => {
        if (res.data) {
          parseEvents(res.data);
          toast("success", "Votre compte rendu d'activité a été envoyé !");
        }
        setLoading(false);
      })
      .catch((e) => toastError(e));
  };

  const handleValidateConfirmationModal = () => {
    const { year, month } = match.params;

    const unselectedDays = getRangeWeekDaysBetweenDate(
      new Date(Number(year), Number(month) - 1, 1),
      new Date(Number(year), Number(month)),
      holidays
    ).filter(
      (day) =>
        !events.find(
          (e) =>
            new Date(e.unparsedDate).toLocaleDateString() ===
            new Date(day.date).toLocaleDateString()
        )
    );

    const unselectedEvents = unselectedDays.map((ev) => ({
      id: uuidv4(),
      date: formatDate(new Date(ev.date)),
      unparsedDate: new Date(ev.date),
      percentage: 0,
      display: "background",
    }));

    const newEvents = events.concat(unselectedEvents);
    setEvents(newEvents);

    submitTimesheet(newEvents);
    setOpenConfirmationDialog(false);
  };
  const handleCloseConfirmationModal = () => setOpenConfirmationDialog(false);

  const handleDateClickSelection = (event) => {
    const prevEventsFiltered = events.filter((e) => e.date !== event.date);
    const newsEvents = [...prevEventsFiltered, event];
    setEvents(newsEvents);
    const duration = newsEvents.reduce(
      (prevValue, currValue) => prevValue + currValue.percentage,
      0
    );
    setDuration(duration);
    setOpenSelectDayPopup(false);
  };

  const onNextMonth = (e) => {
    const { month, year, id } = match.params;

    if (Number(match.params.month) === 12) {
      history.push(`/timesheet/${id}/${Number(year) + 1}/1`);
    } else {
      history.push(`/timesheet/${id}/${year}/${Number(month) + 1}`);
    }
    calendarApi && calendarApi.next();
  };

  const onPrevMonth = (e) => {
    const { month, year, id } = match.params;
    if (Number(match.params.month) === 1) {
      history.push(`/timesheet/${id}/${Number(year) + -1}/12`);
    } else {
      history.push(`/timesheet/${id}/${Number(year)}/${Number(month) - 1}`);
    }
    calendarApi && calendarApi.prev();
  };

  const onSelectDays = (args, allDays) => {
    const inDaysDiff =
      (new Date(args.end).getTime() - new Date(args.start).getTime()) /
      (1000 * 3600 * 24);
    if (inDaysDiff > 1) {
      const rangeSelected = getRangeWeekDaysBetweenDate(
        args.start,
        args.end,
        holidays
      );
      const rangeEvents = rangeSelected.map((day) => ({
        id: uuidv4(),
        date: formatDate(new Date(day.date)),
        unparsedDate: day.date,
        percentage: 1,
        display: "background",
      }));

      const selectAllDaysRange = allDays
        ? rangeEvents
        : rangeEvents.filter((re) => !events.find((e) => e.date === re.date));

      const newsEvents = [
        ...events.filter((ev) => !rangeEvents.find((e) => e.date === ev.date)),
        ...selectAllDaysRange,
      ];
      setEvents(newsEvents);
      const duration = newsEvents.reduce(
        (prevValue, currValue) => prevValue + currValue.percentage,
        0
      );
      setDuration(duration);
    }
  };

  const handleOpenSubmission = (e) => {
    setOpenPeriodPopup(true);
  };

  const selectAllDays = () => {
    const { year, month } = match.params;
    const firstDay = new Date(Number(year), Number(month) - 1, 1);
    const lastDay = new Date(Number(year), Number(month));

    const args = {
      allDay: true,
      end: lastDay,
      start: firstDay,
      startStr: firstDay.toISOString(),
      endStr: lastDay.toISOString(),
    };
    if (selectAllOpenDays) {
      onSelectDays(args, true);
      setSelectAllOpenDays(false);
    } else {
      onSelectDays(args, false);
      setSelectAllOpenDays(true);
    }
  };

  const renderEventContent = (eventInfo) => {
    if (eventInfo.event.extendedProps?.isWeekend) {
      return (
        <div className={`${style["event"]}`}>
          <div
            className={`d-flex justify-content-center align-items-center h-100 bg-secondary`}
          >
            <h3>{eventInfo.event.extendedProps.percentage}</h3>
          </div>
        </div>
      );
    }
    if (
      eventInfo.event.extendedProps?.isHoliday &&
      eventInfo.event.extendedProps.percentage === 0
    ) {
      return (
        <div className={`${style["event"]}`}>
          <div
            className={`d-flex justify-content-center align-items-center h-100 bg-warning`}
          >
            F
          </div>
        </div>
      );
    }

    return (
      <div
        className={`${style["event"]}
      ${
        eventInfo.event.extendedProps.percentage === 1
          ? style["worked-all-day"]
          : eventInfo.event.extendedProps.percentage === 0.5
          ? style["worked-half-day"]
          : style["absent"]
      }`}
      >
        <div
          className={`d-flex justify-content-center align-items-center h-100 ${
            eventInfo.event.extendedProps.percentage === 0 ? "text-dark" : ""
          }`}
        >
          <h3>{eventInfo.event.extendedProps.percentage}</h3>
        </div>
      </div>
    );
  };

  if (location.pathname === "/timesheet") {
    return (
      loaded && (
        <div className="card col-12 col-md p-4 p-md-5 d-flex flex-column gap-5">
          <MobileNavigation />
          <div className="w-100 d-flex flex-column gap-3">
            <div className="row py-5 gap-4 justify-content-center align-items-center">
              <div className="col-12 col-md-5 d-flex flex-column gap-3">
                <div className="wow-body bold">Nom de la mission</div>

                <div className={`${style["helperButtonContainer"]}`}>
                  <select
                    name="mission"
                    id="mission"
                    className="default-input"
                    onChange={(e) => {
                      history.push(
                        `/timesheet/${e.target.value}/${currentYearToDisplay}/${currentMonthToDisplay}`
                      );
                    }}
                  >
                    <option>Choisissez une mission</option>
                    {missions?.length > 0 &&
                      missions.map((mission, i) => {
                        return (
                          <option key={i} value={mission.id}>
                            {mission.label}
                          </option>
                        );
                      })}
                  </select>
                  <span className={`${style["helperButton"]}`}>
                    <HelperButton link="https://wheelofwork.notion.site/658f334b63db45bfb44c63ab2d989a26?v=fb1971c514144a1681dda440478afb86"></HelperButton>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    );
  }

  return (
    loaded && (
      <div className="card col-12 col-md p-4 p-md-5 d-flex flex-column gap-5">
        {openPeriodPopup && (
          <TimesheetPopup
            events={events}
            show={openPeriodPopup}
            onClose={(e) => setOpenPeriodPopup(false)}
            onValidate={handleSubmission}
          />
        )}
        {openSelectDayPopup && (
          <TimesheetDayPopup
            selectedDay={currentEvent}
            show={openSelectDayPopup}
            onValidate={handleDateClickSelection}
            onClose={(e) => setOpenSelectDayPopup(false)}
          />
        )}
        {openConfirmationDialog && (
          <TimesheetConfirmationPopup
            show={openConfirmationDialog}
            onValidate={handleValidateConfirmationModal}
            onClose={handleCloseConfirmationModal}
          />
        )}

        <MobileNavigation />
        <div className="w-100 d-flex flex-column gap-3">
          <div className="wow-body bold">Nom de la mission</div>

          <select
            name="mission"
            id="mission"
            className="default-input"
            value={match.params.id}
            onChange={(e) => {
              history.push(
                `/timesheet/${e.target.value}/${currentYearToDisplay}/${currentMonthToDisplay}`
              );
            }}
          >
            {missions?.length > 0 &&
              missions.map((mission, i) => {
                return (
                  <option key={i} value={mission.id}>
                    {mission.label}
                  </option>
                );
              })}
          </select>
        </div>
        <div
          className={`${style["date-button"]} ${style["fullDisplayCalendar"]} d-flex flex-column flex-md-row align-items-center`}
        >
          <div className="d-flex flex-row gap-3 align-items-center">
            <div className={style["prev-btn"]} onClick={onPrevMonth}>
              <FiChevronLeft />
            </div>
            <div
              className="wow-subtitle bold d-flex justify-content-center"
              style={{ width: "150px" }}
            >{`${Months[match.params.month - 1]} ${match.params.year}`}</div>
            <div className={style["next-btn"]} onClick={onNextMonth}>
              <FiChevronRight />
            </div>
          </div>
          <div
            className={`${style["timesheet-status"]} wow-body`}
            status={status + ""}
          >
            {status === "ADMIN_APPROVED" || status === "APPROVED"
              ? "Approuvé"
              : status === "DRAFT"
              ? "A remplir"
              : status === "SUBMITTED" || status === "SENT_TO_SIGN"
              ? "En attente d'approbation"
              : status === "REFUSED"
              ? "Refusé"
              : ""}
          </div>
        </div>
        <div
          className={`${style["legend"]} col-12 col-md-11 d-flex  justify-content-between align-items-center mb-3`}
        >
          <div className="d-flex flew-row gap-4 ">
            <div className="d-flex flex-row gap-2 align-items-center">
              <span
                className={`${style["circle"]} ${style["worked-all-day"]}`}
              ></span>
              <div className="small-text bold">1 journée travaillée</div>
            </div>
            <div className="d-flex flex-row gap-2 align-items-center">
              <span
                className={`${style["circle"]} ${style["worked-half-day"]}`}
              ></span>
              <div className="small-text bold">1/2 journée travaillée</div>
            </div>
            <div className="d-flex flex-row gap-2 align-items-center">
              <span className={`${style["circle"]} ${style["absent"]}`}></span>
              <div className="small-text bold">Absent</div>
            </div>
          </div>
          {isTimesheetEditable(status) && (
            <div>
              <button
                className={`${style["legend-button"]}  default-btn`}
                onClick={selectAllDays}
              >
                Sélectionner tous les jours ouvrés
              </button>
            </div>
          )}
        </div>

        {/* calendrier */}

        {loading ? (
          <div className="col-12 position-relative" style={{ height: "400px" }}>
            <Loader />
          </div>
        ) : (
          <>
            <div className={`col-12`}>
              <FullCalendar
                initialDate={
                  new Date(
                    Number(match.params.year),
                    Number(match.params.month) - 1,
                    1
                  )
                }
                longPressDelay={200}
                initialView="dayGridMonth"
                plugins={[dayGridPlugin, interactionPlugin]}
                selectable={isTimesheetEditable(status)}
                dragScroll={isTimesheetEditable(status)}
                headerToolbar={false}
                editable={isTimesheetEditable(status)}
                ref={calendar}
                locale={"fr"}
                dayHeaders={true}
                firstDay={1}
                select={onSelectDays}
                events={events}
                showNonCurrentDates={false}
                dateClick={
                  status !== "ADMIN_APPROVED" &&
                  status !== "APPROVED" &&
                  status !== "SUBMITTED" &&
                  status !== "SENT_TO_SIGN" &&
                  onDateClick
                }
                dayMaxEventRows={1}
                eventContent={renderEventContent}
                initialEvents={events ?? []}
              />
            </div>
            <div className="col-12 small-text bold d-flex flex-row justify-content-start">
              Les jours d'astreinte peuvent être saisis directement dans le
              compte rendu. <br /> N'hésitez pas à ajouter un commentaire pour
              toute remarque.
            </div>
            <div className="col-12 small-text bold d-flex flex-row justify-content-end">
              Jours travaillés ce mois
              <div className="green-ny mx-2">{duration}</div>
            </div>
            {comment && (
              <div className="col-12 small-text bold d-flex flex-column justify-content-end">
                Commentaire associé au CRA
                <textarea
                  disabled={true}
                  readOnly={true}
                  className="text-area default-input my-3"
                  placeholder="Dites-nous en un peu plus sur vous, ce qui vous anime, les projets qui peuvent vous intéresser."
                  value={comment}
                />
              </div>
            )}
            <div className="gap-5 d-flex flex-column flex-md-row justify-content-between my-4 m-auto">
              <button
                className={`default-btn ${style["max-h-btn"]} ${
                  status !== "SUBMITTED" &&
                  status !== "SENT_TO_SIGN" &&
                  style["submit-btn-disabled"]
                }`}
                onClick={handleOpenSubmission}
                disabled={!events.length || !isTimesheetEditable(status)}
              >
                Soumettre le compte rendu d’activité
              </button>
              {(status === "ADMIN_APPROVED" || status === "APPROVED") &&
                linkedSociety?.juridicStructure?.toLowerCase() !==
                  "wage_porting" && (
                  <Link to="/profile/factures">
                    <button className={`default-btn ${style["max-h-btn"]}`}>
                      Transmettre la facture
                    </button>
                  </Link>
                )}
            </div>
          </>
        )}
      </div>
    )
  );
};

export default withRouter(Timesheet);
