export const genders = {
    "MALE": 'Homme',
    "FEMALE": 'Femme',
    "OTHER": 'Ne se prononce pas',
}
export const durations = {
    "SHORT": 'Courte (<6mois)',
    "MEAN": 'Moyenne (entre 6 et 12 mois)',
    "LONG": 'Longue (>12mois)',
}
export const legalStatuses = {
    'self_employed': "Auto entrepreneur",
    'society': 'Société : SAS, EURL, SARL',
    'wage_porting': 'Portage salarial'
}

export const societyType = {
    'EURL': "EURL",
    'EIRL': "EIRL",
    'SASU': "SASU",
    'OTHER': "Autre",
    'SA': "SA",
    'SARL': "SARL",
    'SAS': "SAS",
    'MICRO_ENTREPRISE': "Micro-entreprise",
}

export const legalFilesStatuses = {
    'PENDING': 'En attente',
    'REFUSED': 'Refusé',
    'APPROVED': 'Approuvé'
}
export const contractsStatuses = {
    'PENDING': 'En attente',
    'EXPIRED': 'Expiré',
    'ONGOING': 'En cours',
}
export const contractsTypes = {
    'CONTRACT': 'Contrat',
    'AVENANT': 'Avenant',
}

export const timesheetStatus = {
    DRAFT: "Ébauche",
    SUBMITTED: "Soumis",
    SENT_TO_SIGN: "En attente d'approbation",
    APPROVED: "Approuvé",
    ADMIN_APPROVED: "Approuvé",
    REJECTED: "Refusé",
};

export const factureStatuses = {
    "PAYED": 'Payée',
    "PENDING": 'En attente',
    "APPROVED": 'Approuvée',
    "REFUSED": 'Refusée',
}
export const legalDocumentsNames = {
    'RIB': 'RIB',
    'DOMICILE': 'Justificatif de domicile',
    'URSSAF': 'Attestation URSSAF',
    'ASSURANCE_RESPO_CIVILE': 'Attestation de souscription à une assurance de responsabilité civile professionnelle',
    'INSEE': 'Avis de situation INSEE',
    'FISCALE': 'Attestation fiscale',
    'KBIS': 'Extrait de Kbis',
    'CONTRAT_TRAVAIL': 'Contrat de travail réalisé par la société de portage salarial',
}

export const months = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Décembre']