import React, { useEffect, useState } from "react";
import Loader from "../../../Components/Loader";
import MobileNavigation from "../../../Components/Profil/MobileNavigation";
import style from "./style.module.css";
import { useSelector } from "react-redux";
import AddInvoice from "../../../Components/AddInvoice";
import { FiPaperclip } from "react-icons/fi";
import { GetInvoices } from "../../../Services/Invoice";
import { b64ToObjectUrl } from "../../../Helpers/base64-decode";
import FacturationAutomatique from "../DocumentsLegaux/FacturationAutomatique";

const Invoicing = () => {
  const [open, setOpen] = useState(false);
  const currentConsultant = useSelector(
    (state) => state.userReducer.consultant
  );
  const [loading, setLoading] = useState(false);
  const [invoices, setInvoices] = useState([]);

  useEffect(() => {
    setLoading(true);
    if (currentConsultant) {
      GetInvoices().then((res) => {
        setInvoices(res.data);
        setLoading(false);
      });
    }
  }, [currentConsultant]);

  return (
    <div className="card col-12 col-md p-4 p-md-5 d-flex flex-column gap-5 position-relative">
      {open && <AddInvoice show={open} onClose={(e) => setOpen(false)} />}
      {loading ? (
        <Loader />
      ) : (
        <>
          <MobileNavigation />
          {currentConsultant && currentConsultant.society && currentConsultant.society.juridicStructure?.toLowerCase() !== 'wage_porting'
            && <FacturationAutomatique societyId={currentConsultant.society.id} societyCapital={currentConsultant.society.capital} bankAccount={currentConsultant.society.bankAccount} />}
          <div className="w-100 d-flex flex-column flex-md-row justify-content-between align-items-center gap-3">
            <div className="wow-subtitle bold">Gérer mes factures</div>
          </div>
          {invoices.length ? (
            <div className={style["facturation-table"]}>
              <div
                className={`${style["facturation-table-item"]} ${style["facturation-table-header"]}`}
              >
                <div>
                  <div className="small-text">Mission</div>
                  <div className="small-text d-flex justify-content-center">
                    Date de création
                  </div>
                  {/* <div className="small-text d-flex justify-content-center">
                    Date de paiement
                  </div> */}
                </div>
                <div>
                  <div className="small-text d-flex justify-content-center">
                    Montant
                  </div>
                  {/* <div className="small-text d-flex justify-content-center">
                    Statut
                  </div> */}
                  <div className="small-text">Justificatif</div>
                </div>
              </div>
              {invoices.map((invoice, i) => {
                return (
                  <div key={i} className={`${style["facturation-table-item"]}`}>
                    <div>
                      <div className="small-text bold text-truncate">
                        {invoice.mission.label}
                      </div>
                      <div className="small-text d-flex justify-content-center">
                        {invoice.createdAt &&
                          new Date(invoice.createdAt).toLocaleDateString()}
                      </div>
                      {/* <div className="small-text d-flex justify-content-center">
                        {invoice.paymentDate
                          ? new Date(invoice.paymentDate).toLocaleDateString()
                          : "Non renseignée"}
                      </div> */}
                    </div>
                    <div>
                      <div className={`${style['facturation-status']} small-text d-flex justify-content-center`}
                        status={`${invoice.status}`}>
                        {`${invoice.amount} €`}
                      </div>
                      <a
                        className="wow-bodycursor-pointer"
                        href={
                          invoice?.file &&
                            b64ToObjectUrl(
                              invoice.file.base64Document,
                              invoice.file.filetype
                            )
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FiPaperclip style={{ transform: "rotate(-45deg)" }} />
                      </a>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="w-100 d-flex flex-column justify-content-center align-items-center gap-3">
              <img
                src="/facturation-illustration.svg"
                alt="Facturation illustration"
                className={style["facturation-illustration"]}
              />
              <div className="small-text">
                Vous n'avez aucune facture en ce moment.
              </div>
            </div>
          )}
          <div className="w-100 d-flex flex-column align-items-center gap-4">
            <div>
              <button className="default-btn" onClick={(e) => setOpen(true)}>
                Ajouter une facture
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Invoicing;
